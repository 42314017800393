    // Copyright
    document.getElementById("copyright_year").innerHTML = (new Date).toDateString().split(' ')[3];

    // Video Modal
    (function () {
        function pauseVideo() {
            document.getElementById('video_iframe').pause();
        }
        $(document).on('load', function () {
            pauseVideo();
        });
        $('#video_modal').on('hidden.bs.modal', function (e) {
            pauseVideo();
        });
        $('#video_iframe').hover(function () {
            if (this.hasAttribute("controls")) {
                this.removeAttribute("controls");
            } else {
                this.setAttribute("controls", "controls")
            }
        })
    })();
    // Banner Slider 

    jQuery(document).ready(function () {
        /* initialize the slider based on the Slider's ID attribute */
        // var theslider = $('#banner_slider')

        var theslider = $('.rev_slider');
        if (theslider.length) {
            theslider.show().revolution({
                sliderLayout: "fullscreen",

                // navigation
                navigation: {
                    keyboardNavigation: "on",
                    keyboard_direction: "horizontal",
                    // mouseScrollNavigation: "on",
                    onHoverStop: "off",
                    arrows: {
                        enable: true,
                        style: 'uranus',
                        hide_onleave: true

                    },

                    bullets: {
                        enable: false,

                    }
                },
                // Loop
                stopLoop: 'off',
                stopAfterLoops: -1,
                stopAtSlide: -1,
                shuffle: "off",

                shadow: 0,
                spinner: "off",

                // autoHeight: "off",
                fullScreenAutoWidth: "off",
                fullScreenAlignForce: "off",
                fullScreenOffsetContainer: "",
                fullScreenOffset: "0",



                // Responsive
                // hideThumbsOnMobile: 'off',
                // hideSliderAtLimit: 0,
                // hideCaptionAtLimit: 0,
                // hideAllCaptionAtLilmit: 0,

                fallbacks: {
                    simplifyAll: 'on',
                    nextSlideOnWindowFocus: 'off',
                    disableFocusListener: false
                },
                autoHeight: 'on',
                responsiveLevels: [1240, 1030, 778, 480, 375],
                visibilityLevels: [1240, 1030, 778, 480, 375],
                gridwidth: [1240, 1030, 778, 480, 375],
                gridheight: [768, 768, 960, 735, 720]
            });
            //Listen slide nr. and changes the menu and logo color depending on the slider colors
            theslider.bind("revolution.slide.onchange", function (e, data) {
                jQuery('#callbackinfo').html('Last Event: Slide Changed to ' + data.slideIndex).addClass("changecolor");
                setTimeout(function () {
                    jQuery('#callbackinfo').removeClass("changecolor");
                }, 500);

                // var color = revapi  data.slideIndex
                var currentSlide = theslider.find('li').eq(data.slideIndex - 1);
                var currSlideColor = currentSlide.attr('data-color');

                var $iW = $(window).innerWidth();

                var $logo = $('.logo a img');
                var $logoDark = $('div.logo img.dark');
                var $navi = $('.navigation');

                //if screensize is bigger than 975 make logo/menu dark
                if ($iW >= 975) {
                    //when slider changes get menu color
                    if (typeof currSlideColor != 'undefined') {
                        $navi.removeClass('white dark').addClass(currSlideColor);

                        //If the navigation is small then the navigation is always black
                        //If is not small then it gets the color from te slider
                        if ($navi.hasClass('navbg')) {
                            $logo.removeClass('white dark').addClass('dark');

                            //If navigation is darkchealkk make logo white
                            if ($navi.hasClass('darkchealk')) {
                                $logoDark.addClass('white').removeClass('dark');
                            }

                        } else {
                            $logo.removeClass('white dark').addClass(currSlideColor);
                        }
                    } else {
                        $navi.removeClass('white dark').addClass('white');
                        $logo.removeClass('white dark').addClass('white');
                    }
                } else {
                    //if screensize is smaller than 975 make logo/menu black
                    $navi.removeClass('white dark').addClass('dark');
                    $logo.removeClass('white dark').addClass('dark');
                }

                //Ads logo color when you scroll up depending on the background color, 
                //because it changes to black when the menu minimizes.

                $(window).scroll(function () {
                    var $iW = $(window).innerWidth();
                    if ($iW >= 975) {
                        if ($(window).scrollTop() == 0) {
                            if (typeof currSlideColor != 'undefined') {
                                $logo.removeClass('white dark').addClass(currSlideColor);
                                $navi.removeClass('white dark').addClass(currSlideColor);
                            } else {
                                $logo.removeClass('white dark').addClass('white');
                                $navi.removeClass('white dark').addClass('dark');
                            }
                        }
                    } else {
                        if ($(window).scrollTop() == 0) {
                            $navi.removeClass('white dark').addClass('dark');
                            $logo.removeClass('white dark').addClass('dark');
                        }

                        $navi.removeClass('white dark').addClass('dark');
                        $logo.removeClass('white dark').addClass('dark');
                    }
                });

                //Adds dark logo when width is small and white or dark if bigger
                $(window).resize(function () {
                    var $iW = $(window).innerWidth();
                    if ($iW <= 975) {
                        $navi.removeClass('white dark').addClass('dark');
                        $logo.removeClass('white dark').addClass('dark');
                    } else {
                        //when slider changes get menu color
                        if (typeof currSlideColor != 'undefined') {
                            $navi.removeClass('white dark').addClass(currSlideColor);

                            if ($(window).scrollTop() < 800) {
                                $logo.removeClass('white dark').addClass(currSlideColor);
                            } else {
                                $logo.removeClass('white dark').addClass('dark');

                                //If navigation is darkchealkk make logo white
                                if ($navi.hasClass('darkchealk')) {
                                    $logoDark.addClass('white').removeClass('dark');
                                }

                            }

                        } else {
                            $navi.removeClass('white dark').addClass('white');
                        }
                    }
                });
            });
        }
    });

    // Fix Fullscreen Issue
    // if (document.fullscreenElement === null) {
    //     this._fullscreen.openFullscreen();
    // } else {
    //     this._fullscreen.closeFullscreen();
    // }

    // Scroll to DIV
    (function () {
        //  Scroll to Anchors
        $('a[href*="#"]:not([data-toggle="tab"]):not([href="#"]):not([href="#!"]):not([class*="control"]):not([class*="modal"]):not([data-toggle="collapse"]):not([href*="modal"]').on('click', function () {
            target = $(this.hash);
            $('html, body').animate({
                    scrollTop: $(target).offset().top
                },
                1000
            );
        });
    })();


    // Formvalidation  
    (function () {
        // recaptcha
        window.verifyRecaptchaCallback = function (response) {
            $('input[data-recaptcha]').val(response).trigger('change')
        }

        window.expiredRecaptchaCallback = function () {
            $('input[data-recaptcha]').val("").trigger('change')
        }
        // init form
        $('form').each(function () {
            let form = $(this),
                method = form.attr('method'),
                action = form.attr("data-action"),
                recaptcha_elm = form.find('.g-recaptcha'),
                form_message = form.find(".form_massage");
            // show recaptcha on focus
            form.find(".form-control").focus(function () {
                recaptcha_elm.slideDown();
            });
            // init validator
            form.validator();
            // form submit
            form.on('submit', function (e) {

                // if founded missed data
                if (e.isDefaultPrevented()) {
                    form_message.slideDown().end()
                        .find(".alert").addClass("alert-danger").end()
                        .find('.text').html(`<strong> Error! </strong> 
                        please enter the missed data first`);

                }
                // If no missed data ( ajax call)
                else {

                    $.ajax({
                        type: method != null || method != '' ? method : 'POST',
                        url: action,
                        data: form.serialize(),
                        success: function (data) {
                            // add success statement & show it
                            form_message.slideDown().end()
                                .find(".alert").addClass("alert-success")
                                .find('.text').html(`<strong>Thank You,</strong> Your request has been sent successfully`);

                            // reset form & recaptcha
                            form[0].reset();
                            grecaptcha.reset();
                            recaptcha_elm.slideUp();

                            // hide the alert
                            setTimeout(function () {
                                form_message.slideYo().end()
                                    .find(".alert").removeClass("alert-danger alert-success").end()
                                    .find(".text").html(``);
                            }, 4000);


                        },
                        error: function (data) {
                            // add error statement & show it
                            form_message.slideDown().end()
                                .find(".alert").addClass("alert-danger").end()
                                .find(".text").html(`<strong> oops! </strong>
                                There is something wrong! Please try Again`);
                        }
                    })

                }
                // prevent reload page or go to blank page 
                return false;
            })
        })
    })();


    // Google map
    (function () {
        var loadmap = $('body').hasClass('loadmap') || $("#googleMap").length
        if (loadmap) {
            //<![CDATA[
            var geocoder = new google.maps.Geocoder();
            var latitude = "29.990081787109375";
            var longitude = "31.132129669189453";
            // var color = "#333"; //Set your tint color. Needs to be a hex value.

            // function getGeocode() {
            // 	geocoder.geocode({
            // 		'address': address
            // 	}, function (results, status) {
            // 		if (status == google.maps.GeocoderStatus.OK) {
            // 			latitude = results[0].geometry.location.lat();
            // 			longitude = results[0].geometry.location.lng();
            // 			initGoogleMap();
            // 		} else {
            // 			alert("address not found");
            // 		}
            // 	});
            // }

            function initGoogleMap() {
                var styles = [{
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "saturation": 36
                    }, {
                        "color": "#000000"
                    }, {
                        "lightness": 40
                    }]
                }, {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "visibility": "on"
                    }, {
                        "color": "#000000"
                    }, {
                        "lightness": 16
                    }]
                }, {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 20
                    }]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 17
                    }, {
                        "weight": 1.2
                    }]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 20
                    }]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 21
                    }]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 17
                    }]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 29
                    }, {
                        "weight": 0.2
                    }]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 18
                    }]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 16
                    }]
                }, {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }, {
                        "lightness": 19
                    }]
                }, {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#32bbf3"
                    }, {
                        "lightness": 17
                    }]
                }]

                var options = {
                    mapTypeControlOptions: {
                        mapTypeIds: ['Styled']
                    },
                    center: new google.maps.LatLng(latitude, longitude),
                    zoom: 14,
                    scrollwheel: false,
                    navigationControl: false,
                    mapTypeControl: true,
                    zoomControl: true,
                    disableDefaultUI: true,
                    mapTypeId: 'Styled'
                };
                var div = document.getElementById('googleMap');
                var map = new google.maps.Map(div, options);
                marker = new google.maps.Marker({
                    map: map,
                    draggable: false,
                    animation: google.maps.Animation.DROP,
                    position: new google.maps.LatLng(latitude, longitude)
                });
                var styledMapType = new google.maps.StyledMapType(styles, {
                    name: 'Styled'
                });
                map.mapTypes.set('Styled', styledMapType);

                var infowindow = new google.maps.InfoWindow({
                    content: "<div class='iwContent'>" + address + "</div>"
                });
                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.open(map, marker);
                });


                bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(-84.999999, -179.999999),
                    new google.maps.LatLng(84.999999, 179.999999));

                rect = new google.maps.Rectangle({
                    bounds: bounds,
                    // fillColor: color,
                    fillOpacity: 0.0,
                    strokeWeight: 0,
                    map: map
                })
            }
            google.maps.event.addDomListener(window, 'load', initGoogleMap);
            //]]>

            //This must be loaded after google maps
            // getGeocode();
        }
    })()